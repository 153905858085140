import React from 'react'
import { Link } from 'react-router-dom'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import { Table, Pagination, Form, Input, Button } from 'antd'
import { statPointsDetail } from './../../service/stats'
import { FormComponentProps } from 'antd/lib/form'

export interface IPointsStatDetailProps extends FormComponentProps {}
interface IPointsStatDetailStates {
  studentId: any
  regiProvinId: any
  regiCityId: any
  regiCountrId: any
  startDate: any
  endDate: any
  studentTitle: string
  pageSize: number
  page: number
  dataSource: any
  total: number
  loading: boolean
}

const { Column } = Table
class PointsStatDetail extends React.Component<IPointsStatDetailProps, IPointsStatDetailStates> {
  constructor(props: any) {
    super(props)
    this.showTotal = this.showTotal.bind(this)
    this.state = {
      studentId: props.query.studentId,
      regiProvinId: props.query.regiProvinId,
      regiCityId: props.query.regiCityId,
      regiCountrId: props.query.regiCountrId,
      startDate: props.query.startDate,
      endDate: props.query.endDate,
      studentTitle: props.query.title || '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      total: 0,
      loading: false,
    }
  }
  componentDidMount() {
    this.getStudentPointsList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  handleSearch = async () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getStudentPointsList()
    }, 0)
  }

  getStudentPointsList = async () => {
    const values = this.props.form!.getFieldsValue()
    this.setState({
      loading: true,
    })
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      studentId: this.state.studentId,
      regiProvinId: this.state.regiProvinId,
      regiCityId: this.state.regiCityId,
      regiCountrId: this.state.regiCountrId,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      ...values,
    }
    const res = await statPointsDetail(params)
    // {total:,rows:[{
    // provinName:,cityName:,countrName:,studentId:,stuName:,gender:,phone:,compName:,score:,endDateTime:
    // },...],msg:成功,msgStatsNum:1}
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getStudentPointsList()
    }, 0)
  }
  public render() {
    return (
      <div className="w-full h-full">
        <BreadcrumbCustom
          first={<Link to={'/app/stat/points'}>统计管理</Link>}
          second={<Link to={'/app/stat/points'}>积分统计</Link>}
          third={this.state.studentTitle}
        />
        <Main
          contentTitle={this.state.studentTitle}
          centerContent={
            <div>
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                key="table1"
                loading={this.state.loading}
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="姓名"
                  dataIndex="stuName"
                  key="stuName"
                  render={(stuName: any) => stuName}
                />
                <Column
                  title="企业名称"
                  dataIndex="compName"
                  key="compName"
                  render={(compName: any) => compName}
                />
                <Column
                  title="课程标题"
                  dataIndex="title"
                  key="title"
                  render={(title: any) => title}
                />
                <Column
                  title="学习时间"
                  dataIndex="endDateTime"
                  key="endDateTime"
                  render={(endDateTime: any) => endDateTime}
                />
                <Column
                  title="获得积分"
                  dataIndex="score"
                  key="score"
                  render={(score: any) => score}
                />
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}

export default Form.create<IPointsStatDetailProps>()(PointsStatDetail)
