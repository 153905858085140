/**
 * Created by 叶子 on 2017/7/30.
 * 接口地址配置文件
 */

// 测试环境
// export const SERVER_URL = 'http://172.16.0.84:58080/training/'
// export const IMAGE_SERVER = 'http://172.16.0.84:58080/training/'
// export const BASE_URL = '/training/'

// 生产环境
export const SERVER_URL = 'https://train.wazert.com/prod-api/'
export const IMAGE_SERVER = 'https://train.wazert.com/prod-api/'
export const BASE_URL = '/prod-api/'

export const LOGIN = BASE_URL + 'accountcs/veriFClou'
export const VALID_CODE = BASE_URL + 'accountcs/valiCodeFClouBase64'

export const SET_TOP = BASE_URL + 'communalcs/setIsTop'

export const REGION = BASE_URL + 'platf0infcs/accountToArea'
export const GLOBAL_REGION = BASE_URL + 'communalcs/pcc'

export const QUES_LIST = BASE_URL + 'platf0bancs/subjectList'
export const QUES_ONE = BASE_URL + 'platf0bancs/subjectOne'
export const QUES_SAVE = BASE_URL + 'platf0bancs/subjectAddMod'
export const QUES_DEL = BASE_URL + 'platf0bancs/subjectMoreDele'
export const QUES_EXPORT = BASE_URL + 'platf0bancs/subjectExpExce'
export const QUES_IMP = BASE_URL + 'platf0bancs/subjectImpExce'

export const GROUP_LIST = BASE_URL + 'platf0infcs/groupList'
export const GROUP_ONE = BASE_URL + 'platf0infcs/groupOne'
export const GROUP_SAVE = BASE_URL + 'platf0infcs/groupAddMod'
export const GROUP_DEL = BASE_URL + 'platf0infcs/groupMoreDele'
export const GROUP_MOVE = BASE_URL + 'platf0infcs/groupSerialAddMod'

// 账号相关接口
export const ACC_LIST = BASE_URL + 'platf0syscs/accountList'
export const ACC_ONE = BASE_URL + 'platf0syscs/accountOne'
export const ACC_DEL = BASE_URL + 'platf0syscs/accountMoreDele'
export const ACC_SAVE = BASE_URL + 'platf0syscs/accountAddMod'
export const ACC_PWD_MOD = BASE_URL + 'platf0syscs/accountMod'

// 权限
export const PERM_LIST = BASE_URL + 'platf0syscs/permList'
export const PERM_ONE = BASE_URL + 'platf0syscs/permOne'
export const PERM_DEL = BASE_URL + 'platf0syscs/permMoreDele'
export const PERM_SAVE = BASE_URL + 'platf0syscs/permAddMod'
export const PERM_TREE = BASE_URL + 'communalcs/hierarchyPerm'

// 角色
export const ROLE_LIST = BASE_URL + 'platf0syscs/rolePermList'
export const ROLE_ONE = BASE_URL + 'platf0syscs/rolePermOne'
export const ROLE_DEL = BASE_URL + 'platf0syscs/rolePermMoreDele'
export const ROLE_SAVE = BASE_URL + 'platf0syscs/rolePermAddMod'

// 课程
export const COURSE_LIST = BASE_URL + 'platf0leacs/courseList'
export const COURSE_ONE = BASE_URL + 'platf0leacs/courseOne'
export const COURSE_SAVE = BASE_URL + 'platf0leacs/courseAddMod'
export const COURSE_DEL = BASE_URL + 'platf0leacs/courseMoreDele'

// 课件
export const COURSE_WARE_LIST = BASE_URL + 'platf0leacs/textList'
export const COURSE_WARE_ONE = BASE_URL + 'platf0leacs/textOne'
export const COURSE_WARE_SAVE = BASE_URL + 'platf0leacs/textMod'
export const COURSE_WARE_DEL = BASE_URL + 'platf0leacs/textMoreDele'
export const COURSE_WARE_UPLOAD = BASE_URL + 'platf0leacs/textUpload'

// 课程章节
export const CHAPTER_LIST = BASE_URL + 'platf0leacs/chapterList'
export const CHAPTER_ONE = BASE_URL + 'platf0leacs/chapterOne'
export const CHAPTER_SAVE = BASE_URL + 'platf0leacs/chapterAddMod'
export const CHAPTER_DEL = BASE_URL + 'platf0leacs/chapterMoreDele'

// 课程设置
export const COURSE_SETTING = BASE_URL + 'platf0leacs/courseSetList'
export const COURSE_SETTING_SAVE = BASE_URL + 'platf0leacs/courseSetMoreAddMod'

// 试卷
export const TEST_LIST = BASE_URL + 'platf0tescs/paperList'
export const TEST_ONE = BASE_URL + 'platf0tescs/paperOne'
export const TEST_SAVE = BASE_URL + 'platf0tescs/paperAddMod'
export const TEST_DEL = BASE_URL + 'platf0tescs/paperMoreDele'
export const TEST_QUES_RANDOM = BASE_URL + 'platf0tescs/paperSubjTypeRandom'
export const TEST_QUES_RANDOM_NOW = BASE_URL + 'platf0tescs/paperReleSubjFixedPick'
export const TEST_QUES_MANUAL = BASE_URL + 'platf0tescs/paperReleSubjFixedManu'
export const TEST_QUES_MANUAL_RANDOM = BASE_URL + 'platf0tescs/paperSubjFixedRandom'
export const TEST_SETTING_LIST = BASE_URL + 'platf0tescs/paperSetMore'
export const TEST_SETTING_SAVE = BASE_URL + 'platf0tescs/paperSetMoreAddMod'
export const TEST_QUES_GROUP = BASE_URL + 'platf0bancs/subjectTypeMore'
export const TEST_PUBLISH = BASE_URL + 'platf0tescs/paperPublishMod'

// 学员
export const STU_LIST = BASE_URL + 'platf0infcs/studentList'
export const STU_ONE = BASE_URL + 'platf0infcs/studentOne'
export const STU_SAVE = BASE_URL + 'platf0infcs/studentAddMod'
export const STU_DEL = BASE_URL + 'platf0infcs/studentMoreDele'
export const STU_IMP = BASE_URL + 'platf0infcs/studentImpExce'
export const STU_EXP = BASE_URL + 'platf0infcs/studentExpExce'
export const STU_AUDIT = BASE_URL + 'platf0infcs/studentAudit'

export const COMP_LIST = BASE_URL + 'platf0infcs/compList'
export const COMP_ONE = BASE_URL + 'platf0infcs/compOne'
export const COMP_SAVE = BASE_URL + 'platf0infcs/compAddMod'
export const COMP_DEL = BASE_URL + 'platf0infcs/compMoreDele'

export const ORGAN_LIST = BASE_URL + 'platf0infcs/organList'
export const ORGAN_ONE = BASE_URL + 'platf0infcs/organOne'
export const ORGAN_SAVE = BASE_URL + 'platf0infcs/organAddMod'
export const ORGAN_DEL = BASE_URL + 'platf0infcs/organMoreDele'

export const CERT_LIST = BASE_URL + 'platf0infcs/ccieList'
export const CERT_ADD = BASE_URL + 'platf0infcs/ccieAddMod'
export const CERT_DEL = BASE_URL + 'platf0infcs/ccieMoreDele'
export const CERT_EXP = BASE_URL + 'platf0infcs/ccieExpExce'
export const CERT_IMP = BASE_URL + 'platf0infcs/ccieImpExce'
export const CERT_NO_GENE = BASE_URL + 'platf0infcs/ccieGeneCcieNo'

export const STATS_PAPER = BASE_URL + 'platf0statcs/paperExamList'
export const STATS_PAPERSTU_TESTING = BASE_URL + 'platf0statcs/paperStuStatis2List'
export const STATS_PAPERSTU_UNTEST = BASE_URL + 'platf0statcs/paperStuStatis1List'
export const STATS_PAPERSTU_TESTED = BASE_URL + 'platf0statcs/paperStuStatis3List'
export const STATS_PAPERSTU = BASE_URL + 'platf0statcs/paperStuStatisInfo'
export const STATS_PAPERSTU_EXP = BASE_URL + 'platf0statcs/paperStuStatis1ExpExce'
export const STATS_PAPERSTU_SEND = BASE_URL + 'platf0statcs/paperStuStatis1Notice'

export const STATS_COURSE = BASE_URL + 'platf0statcs/courseLearnList'
export const STATS_COURSE_STU = BASE_URL + 'platf0statcs/courseStuStatisList'

export const STATS_POINTS = BASE_URL + 'platf0statcs/scoreList'
export const STATS_POINTS_DETAIL = BASE_URL + 'platf0statcs/scoreDetail'
export const STATS_STU_TEST = BASE_URL + 'platf0statcs/stuExamList'
export const STATS_STU_TEST_DETAIL = BASE_URL + 'platf0statcs/stuExamDetail'
export const STATS_STU_TEST_EXPORT = BASE_URL + 'platf0statcs/stuExamListExpExce'

export const STATS_MONTH_REPORT_ORGAN = BASE_URL + 'platf0statcs/trainMonthReport'
export const STATS_MONTH_REPORT_COMP = BASE_URL + 'platf0statcs/trainCompMonthReport'

export const STATS_MONTH_REPORT_ORGAN_EXP = BASE_URL + 'platf0statcs/trainMonthReportExce'
export const STATS_MONTH_REPORT_COMP_EXP = BASE_URL + 'platf0statcs/trainCompMonthReportExce'

export const PLAT_CERTSNO_LIST = BASE_URL + 'platf0infcs/organCcieNoACMore'
export const PLAT_CERTSNO_MOD = BASE_URL + 'platf0infcs/organCcieNoACMod'

// 账号相关接口
export const NOTICE_LIST = BASE_URL + 'platf0infcs/topicList'
export const NOTICE_ONE = BASE_URL + 'platf0infcs/topicOne'
export const NOTICE_DEL = BASE_URL + 'platf0infcs/topicMoreDele'
export const NOTICE_SAVE = BASE_URL + 'platf0infcs/topicAddMod'
