import React from 'react'
import { Table, Form, Button, Pagination, Input, DatePicker } from 'antd'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import { statStuTest } from './../../service/stats'
import { FormComponentProps } from 'antd/lib/form'
import { PERM, hasPermits } from './../../utils/permits'
import moment from 'moment'
import StuTestStatDetail from './StuTestStatDetail'
import { STATS_STU_TEST_EXPORT } from '../../service/config'
import { download } from './../../service/index'

const { Column } = Table
const { RangePicker } = DatePicker

export interface IStuTestStatProps extends FormComponentProps {}
interface IStuTestStatStates {
  dataSource?: any
  editStuTestStatId: any
  pageSize: number
  page: number
  total: number
  loading: boolean
  startDate: String
  endDate: String
}

class StuTestStat extends React.Component<IStuTestStatProps, IStuTestStatStates> {
  constructor(props: any) {
    super(props)
    this.getStuTestStatList = this.getStuTestStatList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.showTotal = this.showTotal.bind(this)
    this.state = {
      editStuTestStatId: '',
      pageSize: 20,
      page: 1,
      dataSource: [],
      total: 0,
      loading: false,
      startDate: '',
      endDate: '',
    }
  }
  componentDidMount() {
    this.getStuTestStatList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  handleSearch = async () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getStuTestStatList()
    }, 0)
  }

  getStuTestStatList = async () => {
    this.setState({
      loading: true,
    })
    const values = this.props.form!.getFieldsValue()
    const dateFormat = 'YYYY-MM-DD'
    const startDate =
        values.date && values.date.length > 0 ? moment(values.date[0]).format(dateFormat) : ''
    const endDate =
        values.date && values.date.length > 0 ? moment(values.date[1]).format(dateFormat) : ''
    this.setState({
      startDate: startDate,
      endDate: endDate,
    })

    const valuesData = { ...values, date: null }
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      ...valuesData,
      startDate: this.state.startDate? this.state.startDate + ' 00:00:00' : '',
      endDate: this.state.endDate? this.state.endDate + ' 11:59:59': '',
    }

    const res = await statStuTest(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getStuTestStatList()
    }, 0)
  }

  handleExport = async (e: any) => {
    const values = this.props.form!.getFieldsValue()
    const dateFormat = 'YYYY-MM-DD'
    const startDate =
        values.date && values.date.length > 0 ? moment(values.date[0]).format(dateFormat) : ''
    const endDate =
        values.date && values.date.length > 0 ? moment(values.date[1]).format(dateFormat) : ''
    this.setState({
      startDate: startDate,
      endDate: endDate,
    })

    const valuesData = { ...values, date: null }
    const params = {
      rows: this.state.pageSize,
      page: this.state.page,
      ...valuesData,
      startDate: this.state.startDate ? this.state.startDate + ' 00:00:00' : '',
      endDate: this.state.endDate ? this.state.endDate + ' 11:59:59' : '',
    }
    await download(STATS_STU_TEST_EXPORT, '学员考试统计', params)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="统计管理" second="学员考试统计" />
        <Main
          contentTitle="学员考试统计"
          centerContent={
            <div>
              {hasPermits(PERM.stat_study_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="企业名称">
                    {getFieldDecorator('compName')(<Input placeholder="企业名称" allowClear />)}
                  </Form.Item>
                  <Form.Item label="机构名称">
                    {getFieldDecorator('organName')(<Input placeholder="机构名称" allowClear />)}
                  </Form.Item>
                  <Form.Item label="学员名称">
                    {getFieldDecorator('stuName')(<Input placeholder="学员名称" allowClear />)}
                  </Form.Item>
                  <Form.Item label="日期">{getFieldDecorator('date')(<RangePicker />)}</Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>

                    <Button icon="export" type="primary" ghost onClick={this.handleExport}>
                      导出
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                current={this.state.page}
                size="small"
                pageSizeOptions={['10', '20', '50', '100', '200', '500', '1000']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    this.setState({
                      pageSize: size,
                    })
                    this.onPageChange(1)
                  }, 0)
                }}
              />
              <Table
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200', '1000'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                loading={this.state.loading}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="学员姓名"
                  dataIndex="stuName"
                  key="stuName"
                  render={(stuName: any) => stuName}
                />
                <Column
                  title="性别"
                  dataIndex="gender"
                  key="gender"
                  render={(gender: any) => gender}
                />
                <Column
                  title="手机号"
                  dataIndex="phone"
                  key="phone"
                  render={(phone: any) => phone}
                />
                <Column
                  title="身份证"
                  dataIndex="cardID"
                  key="cardID"
                  render={(cardID: any) => cardID}
                />
                <Column
                  title="所在单位"
                  dataIndex="compName"
                  key="compName"
                  render={(compName: any) => compName}
                />
                <Column
                  title="所属机构"
                  dataIndex="organName"
                  key="organName"
                  render={(organName: any) => organName}
                />
                <Column
                  title="考试次数"
                  dataIndex="examNum"
                  key="examNum"
                  render={(examNum: any) => examNum}
                />
                <Column
                  title="最近一次考试时间"
                  dataIndex="lastEndDateTime"
                  key="lastEndDateTime"
                  render={(lastEndDateTime: any) => lastEndDateTime}
                />

                <Column
                  title="操作"
                  dataIndex="studentId"
                  key="studentId"
                  render={(studentId: any) => (
                    <StuTestStatDetail
                      studentId={studentId}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                    />
                  )}
                />
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}
export default Form.create<IStuTestStatProps>()(StuTestStat)
