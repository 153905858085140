/**
 * 路由组件出口文件
 * yezi 2018年6月24日
 */
import Dashboard from './dashboard/Dashboard'
import Student from './student/Student'
import TestPaper from './testpaper/TestPaper'
import Ques from './ques/Ques'
import Course from './course/Course'
import CourseWare from './course/CourseWare'
import CourseAdd from './course/CourseAdd'
import PaperStat from './statistics/PaperStat'
import PaperStatDetail from './statistics/PaperStatDetail'
import CourseStat from './statistics/CourseStat'
import CourseStatDetail from './statistics/CourseStatDetail'
import PointsStat from './statistics/PointsStat'
import PointsStatDetail from './statistics/PointsStatDetail'
import Certs from './certs/Certs'
import Account from './account/Account'
import Permission from './account/Permission'
import Role from './account/Role'
import Settings from './settings/Settings'
import TestQuesEdit from './testpaper/TestQuesEdit'
import TestQuesAdd from './testpaper/TestPaperAdd'
import PlatformCerts from './platform/PlatformCerts'
import AccountPwdMod from './account/AccountPwd'
import Notice from './platform/notice/Notice'
import StuTestStat from './statistics/StuTestStat'
import Comp from './comp/Comp'
import Organ from './organ/Organ'
import MonthReportStat from './statistics/MonthReportStat'

export default {
  Dashboard,
  Student,
  TestPaper,
  Ques,
  Course,
  CourseWare,
  PaperStat,
  CourseStat,
  PaperStatDetail,
  Certs,
  Account,
  Permission,
  Role,
  Settings,
  TestQuesEdit,
  TestQuesAdd,
  CourseAdd,
  CourseStatDetail,
  PointsStat,
  PointsStatDetail,
  PlatformCerts,
  AccountPwdMod,
  Notice,
  StuTestStat,
  Comp,
  Organ,
  MonthReportStat,
} as any
