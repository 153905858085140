import * as React from 'react'
import { Button, Input, Tree, Popover, Divider, Modal } from 'antd'
import { GroupType } from './../../utils/index'
import { fetchGroupList, saveGroup, delGroup } from './../../service/index'

const { Search } = Input
const { TreeNode, DirectoryTree } = Tree
const { confirm } = Modal

export interface IGroupProps {
  onHandleSearch?: (value: string) => void
  buttonTitle?: string
  searchPlaceHolder?: string
  onSelect?: (e: any) => void // 单选事件返回
  onCheck?: (e: any) => void // 多选事件返回
  selectKeys?: any[]
  multi?: boolean // 是否是多选
  type: GroupType // 分组类别
  disableEdit?: boolean
}
interface IGroupStates {
  expandedKeys: []
  searchValue: string
  autoExpandParent: boolean
  checkedKeys?: any[]
  groupList?: any
  selectKeys: any[]
  showEdit: boolean
  searchGroupName: string
  newGroupName: string
  editGroupName: string
}

const TestGroupList = [{ groupName: '所有试卷', groupId: null }]

const QuesGroupList = [{ groupName: '所有题目', groupId: null }]

const StuGroupList = [{ groupName: '所有考生', groupId: null }]

const CourseGroupList = [{ groupName: '所有课程', groupId: null }]

const CourseWareGroupList = [{ groupName: '所有课件', groupId: null }]

export default class Group extends React.Component<IGroupProps, IGroupStates> {
  constructor(props: any) {
    super(props)
    this.onAddGroup = this.onAddGroup.bind(this)
    this.getGroupList = this.getGroupList.bind(this)
    this.getDefaultGroupList = this.getDefaultGroupList.bind(this)
    this.onShowGroupDelete = this.onShowGroupDelete.bind(this)
    this.onShowGroupRename = this.onShowGroupRename.bind(this)
    this.state = {
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: true,
      groupList: this.getDefaultGroupList(),
      selectKeys: this.props.selectKeys || ['null'],
      checkedKeys: [],
      showEdit: false,
      searchGroupName: '',
      newGroupName: '新建分类',
      editGroupName: '',
    }
    this.getGroupList()
  }

  componentWillReceiveProps(props: any) {}

  getDefaultGroupList = () => {
    let groupList: any = []
    switch (this.props.type) {
      case 1:
        groupList = StuGroupList
        break
      case 2:
        groupList = CourseWareGroupList
        break
      case 3:
        groupList = CourseGroupList
        break
      case 4:
        groupList = QuesGroupList
        break
      case 5:
        groupList = TestGroupList
        break
      default:
        break
    }
    return groupList
  }

  onSelect = (keys: any, e: any) => {
    if (this.props.onSelect) {
      this.setState({
        selectKeys: keys,
      })
      const selGroups = keys.map((key: any) => {
        if (key != 'null' && key != -1) {
          const group = this.state.groupList.find((item: any) => item.groupId == key)
          return {
            groupId: key,
            groupName: group.groupName,
          }
        }
      })
      this.props.onSelect(selGroups)
    }
  }

  onChecked = (checkedKeys: any, e: any) => {
    if (this.props.onCheck) {
      this.setState({
        checkedKeys: checkedKeys,
      })
      const selGroups = checkedKeys.map((key: any) => {
        const group = this.state.groupList.find((item: any) => item.groupId == key)
        return {
          groupId: key,
          groupName: group.groupName,
        }
      })
      this.props.onCheck(selGroups)
    }
  }

  onChange = (e: any) => {
    const { value } = e.target
    const expandedKeys = this.state.groupList
      .map((item: any) => {
        if (item.groupName.indexOf(value) > -1) {
          return this.getParentKey(item.groupId, this.state.groupList)
        }
        return null
      })
      .filter((item: any, i: number, self: any) => item && self.indexOf(item) === i)
    this.setState({
      expandedKeys,
      searchValue: value,
      autoExpandParent: true,
    })
  }

  getParentKey = (key: string, tree: any): any => {
    let parentKey
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i]
      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentKey = node.key
        } else if (this.getParentKey(key, node.children)) {
          parentKey = this.getParentKey(key, node.children)
        }
      }
    }
    return parentKey
  }

  onExpand = (expandedKeys: any) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    })
  }

  getGroupList = async () => {
    const params = {
      groupType: this.props.type,
      groupName: this.state.searchGroupName,
    }
    const res = await fetchGroupList(params)
    if (res.success) {
      const groupList = [...this.getDefaultGroupList(), ...res.data]
      this.setState({
        groupList: groupList
      })
    }
  }

  onAddGroup = async (value: any, groupId: any) => {
    if (groupId) {
      value = this.state.editGroupName
    }
    const params = { groupType: this.props.type, groupName: value, groupId: groupId }
    const res = await saveGroup(params)
    if (res.success) {
      this.setState({ showEdit: false })
      this.getGroupList()
    }
  }

  onShowGroupDelete = async (group: any) => {
    confirm({
      title: '确定删除此分类吗?',
      okText: '删除',
      okType: 'danger',
      cancelText: '取消',
      onOk: async () => {
        const res = await delGroup([group.groupId])
        if (res.success) {
          this.getGroupList()
        }
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  onShowGroupRename = (group: any) => {
    group.edit = true
    this.setState({
      editGroupName: group.groupName,
      showEdit: false,
    })
  }

  public render() {
    const loop = (data: any) =>
      data.map((item: any) => {
        const index = item.title.indexOf(this.state.searchValue)
        const beforeStr = item.title.substr(0, index)
        const afterStr = item.title.substr(index + this.state.searchValue.length)
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ color: '#f50' }}>{this.state.searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{item.title}</span>
          )
        if (item.children) {
          if (item.groupId) {
            return (
              <TreeNode key={item.groupId} title={title}>
                {loop(item.children)}
              </TreeNode>
            )
          } else {
            return (
              <TreeNode key={item.groupId} title={title} disabled disableCheckbox>
                {loop(item.children)}
              </TreeNode>
            )
          }
        }
        return <TreeNode key={item.groupId} title={title} />
      })

    const nodeTitle = (group: any): React.ReactNode => {
      return !group.groupId ? (
        group.groupName
      ) : !group.edit ? (
        <Popover
          key={group.groupId}
          content={
            <div>
              <div onClick={() => this.onShowGroupDelete(group)}>删除分类</div>
              <Divider />
              <div onClick={() => this.onShowGroupRename(group)}>重命名</div>
            </div>
          }
          trigger="contextMenu"
        >
          <span>{group.groupName}</span>
        </Popover>
      ) : (
        <Input
          placeholder="编辑分类"
          value={this.state.editGroupName}
          size="small"
          onChange={(e) => {
            this.setState({ editGroupName: e.currentTarget.value })
          }}
          onBlur={(e) => this.onAddGroup(e.currentTarget.value, group.groupId)}
          onPressEnter={(e) => this.onAddGroup(e.currentTarget.value, group.groupId)}
        />
      )
    }

    const groupDisableProps = {
      disableCheckbox: true,
    }

    // 树
    const tree = (
      <DirectoryTree
        multiple
        checkable={this.props.multi}
        defaultExpandAll
        onSelect={this.onSelect}
        onCheck={this.onChecked}
        onExpand={this.onExpand}
        selectedKeys={this.state.selectKeys}
        checkedKeys={this.state.checkedKeys}
        expandedKeys={this.state.expandedKeys}
        autoExpandParent={this.state.autoExpandParent}
      >
        {this.state.groupList &&
          this.state.groupList.map((group: any) => (
            <TreeNode
              title={this.props.disableEdit ? group.groupName : nodeTitle(group)}
              {...(!group.groupId && groupDisableProps)}
              key={group.groupId}
            ></TreeNode>
          ))}

        {this.state.showEdit && (
          <TreeNode
            title={
              <Input
                placeholder="新建分类"
                value={this.state.newGroupName}
                size="small"
                onChange={(e) => {
                  console.log(e)
                  this.setState({
                    newGroupName: e.currentTarget.value,
                  })
                }}
                onBlur={(e) => this.onAddGroup(e.currentTarget.value, null)}
                onPressEnter={(e) => this.onAddGroup(e.currentTarget.value, null)}
              />
            }
            key="-1"
          ></TreeNode>
        )}
      </DirectoryTree>
    )

    // 搜索框
    let search = (
      <Search
        placeholder={this.props.searchPlaceHolder ? this.props.searchPlaceHolder : '搜索'}
        onSearch={(value) => this.props.onHandleSearch && this.props.onHandleSearch(value)}
        enterButton
      />
    )

    // button
    const button = (
      <Button
        type="primary"
        className="mb-s"
        block
        onClick={() => this.setState({ showEdit: true, newGroupName: '新建分类' })}
      >
        {this.props.buttonTitle ? this.props.buttonTitle : '新建分类'}
      </Button>
    )
    return (
      <div>
        {!this.props.disableEdit && button}
        {tree}
      </div>
    )
  }
}
