import * as React from 'react'
import { List, Form, Button, Checkbox, Popconfirm, message, Modal } from 'antd'
import { Link } from 'react-router-dom'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import Group from '../group/group'
import CourseInfo from './CourseInfo'
import CourseSetting from './CourseSetting'
import CourseChapter from './CourseChapter'
import { GroupType } from './../../utils/index'
import GroupSel from './../widget/GroupSel'
import { moveToGroup, setTop } from './../../service/index'
import { courseList, courseDel } from './../../service/course'
import { IMAGE_SERVER } from '../../service/config'
import ModelButton from './../widget/ModelButton'
import { PERM, hasPermits } from './../../utils/permits'

export interface ICourseProps {
  history: any
}
interface ICourseStates {
  dataSource?: any
  checkedList: any[]
  indeterminate: boolean
  checkAll: boolean
  pageSize: number
  page: number
  groupId: any
  total: number
  loading: boolean
  editChapterId: any
  editDialogVisible: boolean
}

export default class Course extends React.Component<ICourseProps, ICourseStates> {
  constructor(props: any) {
    super(props)
    this.onPageChange = this.onPageChange.bind(this)
    this.getCourseList = this.getCourseList.bind(this)
    this.onGroupSelect = this.onGroupSelect.bind(this)
    this.onChangeGroup = this.onChangeGroup.bind(this)
    this.onShowEditDialog = this.onShowEditDialog.bind(this)
    this.state = {
      dataSource: [],
      checkedList: [],
      indeterminate: true,
      checkAll: false,
      pageSize: 10,
      page: 1,
      groupId: '',
      total: 0,
      loading: false,
      editChapterId: '',
      editDialogVisible: false,
    }
  }
  componentDidMount() {
    this.getCourseList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  getCourseList = async () => {
    const params = { page: this.state.page, rows: this.state.pageSize, groupId: this.state.groupId }
    this.setState({ loading: true })

    const res = await courseList(params)
    if (res.success) {
      this.setState({
        dataSource: res.data,
        total: res.total,
        loading: false,
      })
    }
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getCourseList()
    }, 0)
  }

  onGroupSelect = (groups: any) => {
    const group = groups[0]

    setTimeout(() => {
      if (group) {
        this.setState({
          groupId: group.groupId,
          page: 1,
        })
      } else {
        this.setState({
          groupId: '',
          page: 1,
        })
      }
      this.getCourseList()
    }, 0)
  }

  onChangeGroup = async (group: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请选择课程')
      return
    }
    const params = { groupId: group.groupId, serialIds: this.state.checkedList.join(',') }
    const res = await moveToGroup(params)
    if (res.success) {
      setTimeout(() => {
        this.setState({ page: 1 })
        this.getCourseList()
      }, 0)
    } else {
      message.error('分组移动失败')
    }
  }

  onSetTop = async (id: any, isTop: any) => {
    const res = await setTop({
      recordType: 3,
      serialIds: id,
      isTop: isTop,
    })
    if (res.success) {
      message.success('置顶成功')
      this.setState({ page: 1 })
      this.getCourseList()
    } else {
      message.error('置顶失败')
    }
  }

  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择账号')
      return
    }
    const res = await courseDel(this.state.checkedList)
    if (res.success) {
      message.success('删除成功')
      this.getCourseList()
    } else {
      message.error('删除失败')
    }
  }
  onDeleteCancel = (e: any) => {}
  onCourseCheck = (e: any): void => {
    const value = e.target.value
    const checked = e.target.checked
    const course = this.state.dataSource.find((item: any) => item.courseId == value)
    course.checked = checked
    if (checked) {
      this.state.checkedList.push(value)
    } else {
      const index = this.state.checkedList.findIndex((item) => item == value)
      this.state.checkedList.splice(index, 1)
    }
    this.setState({
      checkedList: this.state.checkedList,
      indeterminate:
        !!this.state.checkedList.length &&
        this.state.checkedList.length < this.state.dataSource.length,
      checkAll: this.state.checkedList.length === this.state.dataSource.length,
    })
  }
  onCourseCheckAll = (e: any) => {
    const ids = this.state.dataSource.map((item: any) => {
      return item.courseId
    })
    this.setState({
      checkedList: e.target.checked ? ids : [],
      indeterminate: false,
      checkAll: e.target.checked,
    })
  }
  onShowEditDialog = (courseId: any) => {
    setTimeout(() => {
      this.setState({
        editChapterId: courseId,
        editDialogVisible: true,
      })
    }, 0)
  }

  public render() {
    const setTopBtn = (item: any) => {
      if (item.isTop == 1) {
        return (
          <Button type="primary" ghost onClick={() => this.onSetTop(item.courseId, 0)}>
            取消置顶
          </Button>
        )
      } else {
        return (
          <Button type="primary" ghost onClick={() => this.onSetTop(item.courseId, 1)}>
            置顶
          </Button>
        )
      }
    }
    const actions = (item: any) => {
      const actionArray = []
      if (hasPermits(PERM.study_course_func_top)) {
        actionArray.push(setTopBtn(item))
      }
      if (hasPermits(PERM.study_course_func_mod)) {
        actionArray.push(<CourseInfo courseId={item.courseId} />)
      }
      if (hasPermits(PERM.study_course_func_modcapter)) {
        actionArray.push(
          <Button
            icon="ordered-list"
            type="primary"
            onClick={(e) => {
              this.onShowEditDialog(item.courseId)
            }}
          >
            编辑章节信息
          </Button>
        )
      }
      if (hasPermits(PERM.study_course_func_setting)) {
        actionArray.push(<CourseSetting courseId={item.courseId} />)
      }

      return actionArray
    }
    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="课程管理" second="课程列表" />
        <Main
          leftTitle="课程分类列表"
          leftContent={<Group type={GroupType.COURSE} onSelect={this.onGroupSelect} disableEdit={true}/>}
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }}>
                {hasPermits(PERM.study_course_func_add) && (
                  <Form.Item>
                    <Link to={'/app/course/add'}>
                      <Button type="primary" size="large">
                        新增课程
                      </Button>
                    </Link>
                  </Form.Item>
                )}
                {hasPermits(PERM.study_course_func_groupmod) && (
                  <Form.Item>
                    <GroupSel
                      title="移动到分类"
                      buttonTitle="移动到分类"
                      type={GroupType.COURSE}
                      hideInput={true}
                      onChange={this.onChangeGroup}
                      size="large"
                    />
                  </Form.Item>
                )}
                {hasPermits(PERM.study_course_func_del) && (
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除课程吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" size="large" type="danger" ghost>
                        删除课程
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
              <List
                itemLayout="vertical"
                size="large"
                loading={this.state.loading}
                header={
                  <div>
                    <Checkbox
                      className="mr-s"
                      indeterminate={this.state.indeterminate}
                      onChange={this.onCourseCheckAll}
                      checked={this.state.checkAll}
                    >
                      全选
                    </Checkbox>
                  </div>
                }
                pagination={{
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  total: this.state.total,
                  size: 'small',
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
                footer={<div></div>}
                renderItem={(item: any) => (
                  <Checkbox.Group style={{ width: '100%' }} value={this.state.checkedList}>
                    <List.Item
                      key={item.title}
                      actions={actions(item)}
                      extra={
                        <div className="flex-h mr-m">
                          <div className="flex-v y-center ">
                            <div className="text">学习人数</div>
                            <div className="text-2x text-blue">{item.studentNum}</div>
                          </div>
                        </div>
                      }
                    >
                      <List.Item.Meta
                        avatar={
                          <div>
                            <Checkbox
                              className="mr-s"
                              value={item.courseId}
                              onChange={this.onCourseCheck}
                              checked={item.checked}
                            ></Checkbox>

                            {item.coverDirectURL && (
                              <img
                                width={272}
                                height={200}
                                alt="logo"
                                src={IMAGE_SERVER + item.coverDirectURL}
                              />
                            )}
                          </div>
                        }
                        title={<a href={item.href}>{item.title}</a>}
                        description={
                          <div>
                            <div>{item.courseType == 1 ? '必修课' : '选修课'}</div>
                            <div>分类：{item.groupNames || '无'}</div>
                            <div>
                              {'课程时长约：' + item.courseDura / 60}分钟 | 共{item.chapterNum}章节
                            </div>

                            <div>{item.abstract}</div>
                            <div>简介：{item.abstract}</div>
                          </div>
                        }
                      />
                      {item.content}
                    </List.Item>
                  </Checkbox.Group>
                )}
              />
            </div>
          }
        />
        <Modal
          width="60%"
          title="编辑章节信息"
          visible={this.state.editDialogVisible}
          onOk={() => {
            this.setState({
              editDialogVisible: false,
            })
          }}
          onCancel={() => {
            this.setState({
              editDialogVisible: false,
            })
          }}
        >
          <CourseChapter courseId={this.state.editChapterId} />
        </Modal>
      </div>
    )
  }
}
